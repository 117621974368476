@import url('../components/shared/googlemaps/infowindow.module.css');
@import url('../components/shared/swiperkenburn.module.css');

/* Import Tailwind CSS styles, with the base taking lower priority to allow Mantine to work correctly. */
@layer {
  @tailwind base;
}
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

html,
body {
  /* This is for fixing white space on the bottom of the page  */
  /* Ref: https://stackoverflow.com/questions/39070028/mysterious-white-space-at-bottom-of-web-page-in-mobile-chrome */
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

.editor-text {
  font-family: 'Portonovo', serif;
  line-height: 120%;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.006em;
  outline: none;
}

.editor-text1 {
  font-size: 48px;
  padding: 48px 0 16px;
  margin: 0;
}

.editor-text2 {
  font-size: 36px;
  padding: 32px 0 12px;
  margin: 0;
}

.editor-text3 {
  padding: 12px 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.004em;
  font-family: 'TT Commons Regular', sans-serif;
  margin: 0;
}

.editor-text4 {
  padding: 4px 0;
  font-size: 20px;
  font-family: 'TT Commons Regular', sans-serif;
  line-height: 110%;
  letter-spacing: -0.004em;
  margin: 0;
}

@media screen and (max-width: 800px) {
  .editor-text1 {
    font-size: 40px;
  }
  .editor-text2 {
    font-size: 28px;
  }
  .editor-text3 {
    font-size: 24px;
  }
  .editor-text4 {
    font-size: 18px;
  }
}

@media screen and (max-width: 500px) {
  .editor-text1 {
    font-size: 36px;
    padding: 40px 0 16px;
  }
  .editor-text2 {
    font-size: 26px;
  }
  .editor-text3 {
    font-size: 20px;
  }
  .editor-text4 {
    font-size: 18px;
  }
}

/* Editor styling overrides start */

.codex-editor__redactor {
  padding-bottom: 150px !important;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ce-block__content {
  max-width: 100%;
  animation: fadeInAnimation 0.5s ease-in-out;
}

.ce-toolbar__content {
  max-width: 100%;
}

.ce-toolbar__plus {
  margin-right: -8px;
}

@media only screen and (max-width: 650px) {
  .ce-toolbar__plus {
    margin-right: 6px;
  }
}

@media screen and (max-width: 650px) {
    .ce-popover--opened {
        max-height: 250px!important;
        top: 200px!important;
    }
}
.ce-toolbar__plus:hover {
  background-color: transparent;
}

.ce-toolbar__settings-btn {
  margin-right: -2px;
}

.ce-toolbar__settings-btn:hover {
  background-color: transparent;
}

/* .ce-block--focused */
.codex-editor__redactor [contenteditable]:focus:empty:after {
  content: attr(data-placeholder);
  color: #595959;
}

.ce-block__content .ce-paragraph {
  padding-top: 20px;
  font-size: 22px;
  font-family: 'TT Commons Regular', sans-serif;
  line-height: 1.1;
  margin-left: 8px;
}

.toolbar__actions {
  align-items: center;
}

.ce-toolbar__settings-btn:active {
  cursor: grab;
}

.ce-block {
  padding-left: 48px;
  max-width: 100%;
}

@media screen and (max-width: 650px) {
  .ce-block {
    padding: 0 16px;
    max-width: 100%;
  }
}

.ce-block a {
  text-decoration: none;
}

.ce-popover__item-icon svg {
  width: 16px;
  height: 16px;
}

.ce-settings .ce-popover--opened {
  margin-left: 48px;
}

.codex-editor--narrow .codex-editor__redactor {
  margin-right: 0;
}

.ce-toolbar__actions {
  left: 0;
}

@media screen and (max-width: 650px) {
  .codex-editor--narrow .ce-toolbar__actions {
    right: auto;
  }
  .ce-settings .ce-popover--opened {
    margin-left: 0;
  }

  /* Add close icon button */
  .ce-popover::after {
    content: '\2715';
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }

  /* Make popover full size */
  .ce-popover--opened {
    max-height: 100%;
    top: 68px;
  }
}

@media (min-width:651px) {
    .codex-editor--narrow .ce-toolbox {
        left: unset;
        right: unset;
    }
    .codex-editor--narrow .ce-toolbar__actions {
        right: unset;
    }
}

.codex-editor--narrow .ce-toolbox .ce-popover {
  right: unset;
}

/* Editor styling overrides end */

/* Hide scroll bar in infinite scroll component start */

/* Hide scrollbar for IE, Edge and Firefox */
.explore-infinite-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.explore-infinite-scroll::-webkit-scrollbar {
  display: none;
}

/* Customization for drag n drop plugin */
.ce-block--drop-target .ce-block__content:before {
  display: none;
}

.ce-block--drop-target .ce-block__content:after {
  background: none;
}

/* Styling for block settings icon tooltips */
.ct--shown {
  white-space: pre-line;
  left: 8px !important;
}

.ct--shown::after {
  display: none;
}

.ct--shown .ct__content {
  background-color: black;
  color: white;
  border-radius: 7px;
}

/* Hide scroll bar in infinite scroll component end */

/* Styling for unordered list element */
ul {
  margin: 0;
}
